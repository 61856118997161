import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Barra from "../../imgs/barra-yellow.png";
import Impressora from "../../imgs/impressora.png";
import swal from "sweetalert2";
import Pesquisa from "../../components/pesquisaSatisfacao/pesquisaSatisfacao";
import ReactGA from "react-ga";
// import IconeWhatsapp from "../../components/icone-whatsapp/icone-whatsapp";

import "./boletoGerado.css";
// import { cloneElement } from "react";

export default function BoletoGerado() {
  const [openModal, setOpenModal] = useState(false);
  const boleto = useSelector((state) => state.boletoReducer);
  const usuario = useSelector((state) => state.usuarioReducer);
  let i = 0;
  const dispatch = useDispatch();
  const SEGUNDA_VIA = "Segunda Via";

  useEffect(() => {
    dispatch({ type: "SET_LOADING_PAGE", payload: false });
    if (!boleto.gerado)
      setTimeout(() => {
        setOpenModal(true);
      }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boleto]);

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  const setCopy = () => {
    swal.fire({
      type: "success",
      title: '<span style="color: white">Linha Digitável Copiada!!!</span>',
      background: "#3A3A39",
      confirmButtonColor: "#D9991E",
    });
  };

  const visualizarBoleto = () => {
    ReactGA.event({
      category: "Click",
      action: "Visualizar Boleto",
    });
    const win = window.open(boleto.linkPDF);
    win.focus();
  };
  const DESC_DETALHES = `
			<span style="text-align:center">
				<h3 style="font-weight: 100;">Parcelas do Financiamento englobadas no acordo:<b>${boleto?.acordo?.PARCELAS}</b></h3>
				<h3>Valores de encargos originais do acordo:</h3>
				<p>Taxa de juros: <b>${boleto?.acordo?.JUROS_ANUAL}% a.a</b></p>
				<p>Taxa de juros mensal: <b>${boleto?.acordo?.TAXA_JUROS} a.m</b></p>	
				<p>Juros: <b>${boleto?.acordo?.TOTAL_CP}</b></p>
				<p>Mora: <b>${boleto?.acordo?.TOTAL_MORA}</b></p>
				<p>Taxa Mora: <b>${boleto?.acordo?.TAXA_MORA}%</b></p>
				<p>Multa: <b>${boleto?.acordo?.TOTAL_MULTA}</b></p>
				<p>Taxa Multa: <b>${boleto?.acordo?.TAXA_MULTA}%</b></p>
				<p>CET: <b>${boleto?.acordo?.VALOR_CET}% a.a</b></p>
				<p>CET Mensal: <b>${boleto?.acordo?.CET_MENSAL}% a.m</b></p>		
			</span>`;

  const detalhes = () => {
    if (!!boleto?.acordo?.ID_ACORDO) {
      swal.fire({
        title: `Numero do acordo (Crefisa): ${boleto?.acordo?.ID_ACORDO}`,
        html: DESC_DETALHES,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#FAB800",
        allowOutsideClick: false,
      });
    }
  };

  const segundaVia = () => {
    if (boleto.parcelasGeradas && !isEmpty(boleto.parcelasGeradas)) {
      let msg_parcela = "";
      for (const parcela of boleto.parcelasGeradas) {
        msg_parcela += `<div class="segunda-via">
					<div>
						<div class="flex">
							<div>
							<label class="bold">Valor</label>
							<p>${parcela.valor}</p>
							</div>
							<div>
							<label class="bold">Vencimento</label>
							<p>${parcela.vencimento}</p>
							</div>
						</div>
					</div>
					<div>
					<p class="linha-segunda-via">${parcela.linhaDig}</p>
					<a href="${parcela.linkPDF}" target="_blank" class="bt-pdf">Visualizar Segunda Via</a>
				</div>
				</div>`;
      }
      swal.fire({
        title: SEGUNDA_VIA,
        html: msg_parcela,
        confirmButtonColor: "#FAB800",
        background: "#fff",
      });
    }
  };

  return (
    <div className="Container-inicial" onLoad={detalhes}>
      {boleto.beneficiario === undefined ? (
        <div style={{position: 'relative'}}>
          {/* boleto.result.nuWhatsapp === false ? (
            ""
          ) : (
            <IconeWhatsapp numero={boleto.result.nuWhatsapp}></IconeWhatsapp>
          )*/}
          <h1 className="Cabecalho-white">
            {boleto.result.gerado
              ? `Emita agora a segunda via do seu boleto!`
              : `Não espere, pague seu boleto agora!`}
          </h1>
          <div className="wholePaper" id="boletoGerado">
            <div className="PapelTop">
              <img alt="impressora" src={Impressora} className="impressora" />
              <div className="tela-inicial-text-boleto">
                Boleto
                <br />
                Digital
              </div>
              <div className="LinhaUm-boleto-gerado">
                <div>
                  <div className="Title1">
                    VALOR
                    <br />
                    DO BOLETO
                    <br />
                  </div>
                  <div className="subTitleSpace">{boleto.result.aVista}</div>
                </div>
                <div className="linha2-boleto-gerado">
                  <div>
                    <div className="Title">
                      Nome<div className="Texto">{usuario.nome}</div>
                    </div>
                    <div className="TitleSpace">
                      Nº Contrato
                      <div className="Texto">{boleto.result.numContrato}</div>
                    </div>
                  </div>
                  <div>
                    <div className="subTitle-boleto-gerado">
                      {boleto.result.id}
                    </div>
                    <div>
                      <div className="Title-gerado-boleto">
                        Beneficiário
                        <div className="Texto">
                          {boleto.result.beneficiario}
                        </div>
                      </div>
                      <div className="TitleSpace">
                        Vencimento
                        <div className="Texto">{boleto.result.vencimento}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {boleto.result.isSMS ? (
              <div>
                <p
                  className="labelSMS"
                  style={{ fontSize: "1.3em", textAlign: "center" }}
                >
                  Você recebeu um SMS com a linha digitável para realizar o
                  pagamento do boleto.
                </p>
                <p className="labelNaoEspere">Não espere, pague agora!</p>
              </div>
            ) : (
              <div className="PapelBottom">
                <div className="Linha">
                  <img className="Boleto" alt="boleto" src={Barra} />
                  <div className="itensBottom">
                    <p className="textoBottom-boleto-gerado">
                      {boleto.result.linhaDig}
                    </p>
                    <CopyToClipboard
                      onCopy={setCopy}
                      text={boleto.result.linhaDig}
                    >
                      <button className="botao-copiar-boleto-gerado">
                        Copiar linha digitável
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {!boleto.result.isSMS || boleto.result.isSMSComBoleto ? (
              <>
                <span
                  className="labelButtonBig"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Clique e visualize o seu boleto agora mesmo
                </span>
                <button
                  className="Button-boleto-gerado"
                  onClick={visualizarBoleto}
                >
                  {boleto.result.gerado
                    ? "Visualizar Segunda Via"
                    : "Visualizar Boleto"}
                </button>
              </>
            ) : (
              <div style={{ height: "100px" }} />
            )}
            {boleto.result.parcelasGeradas &&
            !isEmpty(boleto.result.parcelasGeradas) ? (
              <button
                className="Button-boleto-gerado"
                id="segundaVia"
                onClick={segundaVia}
              >
                Segunda Via
              </button>
            ) : null}
          </div>
          {!boleto.result.gerado && openModal ? (
            <Pesquisa Close={() => setOpenModal(false)} />
          ) : null}
        </div>
      ) : (
        <div>
          {/* boleto.nuWhatsapp === false ? (
            ""
          ) : (
            <IconeWhatsapp numero={boleto.nuWhatsapp}></IconeWhatsapp>
          )*/}
          <h1 className="Cabecalho-white">
            {boleto.gerado
              ? `Emita agora a segunda via do seu boleto!`
              : `Não espere, pague seu boleto agora!`}
          </h1>
          <div className="wholePaper" id="boletoGerado">
            <div className="PapelTop">
              <img alt="impressora" src={Impressora} className="impressora" />
              <div className="tela-inicial-text-boleto">
                Boleto
                <br />
                Digital
              </div>
              <div className="LinhaUm-boleto-gerado">
                <div>
                  <div className="Title1">
                    VALOR
                    <br />
                    DO BOLETO
                    <br />
                  </div>
                  <div className="subTitleSpace">{boleto.aVista}</div>
                </div>
                <div className="linha2-boleto-gerado">
                  <div>
                    <div className="Title">
                      Nome<div className="Texto">{usuario.nome}</div>
                    </div>
                    <div className="TitleSpace">
                      Nº Contrato<div className="Texto">{boleto.NContrato}</div>
                    </div>
                  </div>
                  <div>
                    <div className="subTitle-boleto-gerado">{boleto.id}</div>
                    <div>
                      <div className="Title-gerado-boleto">
                        Beneficiário
                        <div className="Texto">{boleto.beneficiario}</div>
                      </div>
                      <div className="TitleSpace">
                        Vencimento
                        <div className="Texto">{boleto.vencimento}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {boleto.isSMS ? (
              <div>
                <p
                  className="labelSMS"
                  style={{ fontSize: "1.3em", textAlign: "center" }}
                >
                  Você recebeu um SMS com a linha digitável para realizar o
                  pagamento do boleto.
                </p>
                <p className="labelNaoEspere">Não espere, pague agora!</p>
              </div>
            ) : (
              <div className="PapelBottom">
                <div className="Linha">
                  <img className="Boleto" alt="boleto" src={Barra} />
                  <div className="itensBottom">
                    <p className="textoBottom-boleto-gerado">
                      {boleto.linhaDig}
                    </p>
                    <CopyToClipboard onCopy={setCopy} text={boleto.linhaDig}>
                      <button className="botao-copiar-boleto-gerado">
                        Copiar linha digitável
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {!boleto.isSMS || boleto.isSMSComBoleto ? (
              <>
                <span
                  className="labelButtonBig"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Clique e visualize o seu boleto agora mesmo
                </span>
                <button
                  className="Button-boleto-gerado"
                  onClick={visualizarBoleto}
                >
                  {boleto.gerado
                    ? "Visualizar Segunda Via"
                    : "Visualizar Boleto"}
                </button>
              </>
            ) : (
              <div style={{ height: "100px" }} />
            )}
            {boleto.parcelasGeradas && !isEmpty(boleto.parcelasGeradas) ? (
              <button
                className="Button-boleto-gerado"
                id="segundaVia"
                onClick={segundaVia}
              >
                Segunda Via
              </button>
            ) : null}
          </div>
          {!boleto.gerado && openModal ? (
            <Pesquisa Close={() => setOpenModal(false)} />
          ) : null}
        </div>
      )}
    </div>
  );
}
