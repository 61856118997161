import React from "react";

import Success from "../../imgs/success.svg";
import logoItau from "../../imgs/itau.svg";
import logoFlex from "../../imgs/flex.svg";

import "./telaDesconhece.css";

export default function TelaDesconhece() {
  return (
    <div className="container-tela-desconhece">
      <div className="container-tela-desconhece-content">
		<div className="container-logo">
			<img className="logo-itau" src={logoItau} alt="logo iatú" />
			<div className="divider" />
			<div className="content-logo">
				<img className="logo-flex" src={logoFlex} alt="logo flex" />
				<p>Parceiro autorizado</p>
			</div>
			</div>
        <div className="tela-desconhece-success">
          <img src={Success} alt="success" />
        </div>
        <div className="tela-desconhece-items">
          <div className="tela-desconhece-text">Obrigado pela informação!</div>
        </div>
      </div>
    </div>
  );
}
