import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import Store from './reducers/store';

import Main from './pages/main/main';

ReactDOM.render(
	<Provider store={Store}>
		<Main />
	</Provider>,
	document.getElementById('root')
);
