import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";

import HeaderBar from "../../components/HeaderBar";

import "./telaCPF.css";

import Barra from "../../imgs/barra-title.svg";
import LogoFlex from "../../imgs/flex.png";
import Flex from "../../imgs/powered.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Warning from "../../imgs/warning.png";
import Whatsapp from "../../imgs/whatsapp.png";
import MulherComCelular from "../../imgs/mulher-olhando-o-celular.png";

import Porcentagem from "../../imgs/porcentagem.svg";
import Phone from "../../imgs/phone.svg";
import Like from "../../imgs/like.svg";
import Calendar from "../../imgs/calendar.svg";

import Etapa1 from "../../imgs/etapa1.png";
import Etapa2 from "../../imgs/etapa2.png";
import Etapa3 from "../../imgs/etapa3.png";

import LogoFlexFooter from "../../imgs/footer_logo_flex.svg";

import BoletoActions from "../../actions/boletoActions";
import CPFService from "../../services/cpf";
import HistoricoService from "../../services/historico";

export default function TelaCPF() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const boleto = useSelector((state) => state.boletoReducer);
  const usuario = useSelector((state) => state.usuarioReducer);
  const estado = useSelector((state) => state.estadoReducer);
  const maxLength = usuario.tipo === "cpf" ? 3 : 2;
  const mask =
    usuario.tipo === "cpf"
      ? `.***.${usuario.cpfCnpj}-**`
      : `.***.${usuario.cpfCnpj}/****-**`;
  const placeholder = usuario.tipo === "cpf" ? "_ _ _" : "_ _";
  const historicoService = new HistoricoService(estado);
  const cpfService = new CPFService(estado);
  const boletoActions = new BoletoActions(dispatch, estado, boleto);

  useEffect(() => {
    dispatch({ type: "SET_LOADING_PAGE", payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  const confirmarCPF = async (e) => {
    e.preventDefault();
    setDisabled(true);
    dispatch({ type: "SET_LOADING_PAGE", payload: true });
    ReactGA.event({
      category: "Click",
      action: "Confirmar CPF",
    });
    if (
      (usuario.tipo === "cpf" && usuario.digitos.length === 3) ||
      (usuario.tipo === "cnpj" && usuario.digitos.length === 2)
    ) {
      cpfService
        .validateCPF(usuario.digitos)
        .then(async (data) => {
          if (data.success) {
            dispatch({ type: "SET_TOKEN", payload: data.result.token });
            boletoActions.getDadosBoleto(data.result.token);
          } else {
            dispatch({ type: "SET_LOADING_PAGE", payload: false });
            dispatch({
              type: "SET_ERROR_MODAL",
              payload: `Erro na validação do seu ${usuario.tipo.toUpperCase()}`,
            });
          }
        })
        .catch((err) => {
          dispatch({ type: "SET_LOADING_PAGE", payload: false });
          dispatch({
            type: "SET_ERROR_MODAL",
            payload: `Erro na validação do seu ${usuario.tipo.toUpperCase()}`,
          });
        });
    } else {
      dispatch({
        type: "SET_ERROR_MODAL",
        payload: `${usuario.tipo.toUpperCase()} Incorreto`,
      });
      dispatch({ type: "SET_LOADING_PAGE", payload: false });
    }
    setDisabled(false);
  };

  const renderStringCPFCNPJ = () => {
    if (usuario.tipo === "cpf") {
      return (
        <div>
          Olá, <span>{usuario.nome.split(" ")[0]}&nbsp;&nbsp;</span> <br />
          Quer ver condições exclusivas para negociar sua dívida
        </div>
      );
    } else {
      return (
        <div>
          Olá! Você é responsável por: <br /> {usuario.nomecompleto}?{" "}
        </div>
      );
    }
  };

  const naoConhece = () => {
    historicoService.informaDesconhece((data) => {
      setOpenModal(!openModal);
      if (data.success) {
        dispatch({ type: "SET_CONFIRMA_CPF", payload: false });
        dispatch({ type: "SET_TELA_DESCONHECE" });
      } else {
        dispatch({
          type: "SET_ERROR_MODAL",
          payload: "Erro em nossos servidores, por favor tentar mais tarde! :(",
        });
      }
    });
  };

  const btnNegociarRef = useRef(null);
  const btnWhatsappRef = useRef(null);
  const footerRef = useRef(null);
  const btnNegociarTopRef = useRef(null);

  const btnWhatsappMainFloat = document.getElementById('btn-whatsapp-main-float');

  const intersectionObserver = new IntersectionObserver((entries) => {
    const radioFooter = entries[0].intersectionRatio;

    if (!!radioFooter) {
      btnNegociarRef.current.style.display = "none";
      btnWhatsappMainFloat.style.display = "none";
    } else {
      btnNegociarRef.current.style.display = "block";
      btnWhatsappMainFloat.style.display = "block";

    }
  });

  useEffect(() => {
    intersectionObserver.observe(footerRef.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [intersectionObserver]);

  const handleScroll = () => {
    if (document.documentElement.scrollTop < 530) {
      btnNegociarRef.current.style.opacity = 0;
    }
    if (document.documentElement.scrollTop > 530) {
      btnNegociarRef.current.style.opacity = 1;
    }
  };

  useEffect(() => {
    window.onscroll = () => handleScroll();
    return () => {
      window.onscroll = null;
    };
  }, []);

  const maskPhone = (phone) => {
    if (phone.length === 13) {
      return phone.replace(
        /(\d{2})(\d{2})(\d{5})(\d{4})/,
        (regex, arg1, arg2, arg3, arg4) => {
          return `0${arg2} ${arg3}-${arg4}`;
        }
      );
    } else {
      return phone.replace(
        /(\d{2})(\d{2})(\d{4})(\d{4})/,
        (regex, arg1, arg2, arg3, arg4) => {
          return `0${arg2} ${arg3}-${arg4}`;
        }
      );
    }
  };

  const [numPhone, setNumPhone] = useState("");

  useEffect(() => {
    switch (estado.product) {
      case "pfv":
      case "pfs":
      case "pfe":
        setNumPhone("5511991983429");
        break;

      case "ctv":
      case "cts":
      case "cte":
        setNumPhone("551149493694");
        break;
      default:
    }
  }, [estado]);

  return (
    <>
      <HeaderBar />
      <div className="container-tela-cpf">
        <div className="container-tela-cpf">
          <div className="container-tela-cpf-items">
            <div className="container-tela-cpf-description">
              <p className="tela-cpf-description-title">
                <strong>
                  Negocie agora mesmo sua dívida com condições especiais!
                </strong>
              </p>
              <p className="tela-cpf-description-text">
                Aqui você tem <strong>parcelas</strong> que cabem no seu bolso e{" "}
                <strong>descontos</strong> no pagamento online. É bem simples e
                rápido.
              </p>
            </div>

            <div className="container-tela-cpf-forms">
              <div className="container-tela-cpf-text">
                <h1
                  className="cabecalho-telacpf"
                  style={{
                    fontSize: usuario.tipo === "cpf" ? "1.7em" : "1.6em",
                  }}
                >
                  {renderStringCPFCNPJ()}
                </h1>
                <p className="textoCPF">
                  {`Digite os ${maxLength} primeiros dígitos de seu ${usuario.tipo.toUpperCase()}`}
                </p>
              </div>
              <form className="form-telacpf" onSubmit={confirmarCPF}>
                <div>
                  <input
                    type="text"
                    maxLength={maxLength}
                    className={usuario.tipo}
                    placeholder={placeholder}
                    value={usuario.digitos}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_DIGITOS",
                        payload: e.target.value.replace(/[^0-9]/, ""),
                      })
                    }
                  />
                  <label className={"label-" + usuario.tipo}>{mask}</label>
                </div>
              </form>
              <p className="texto-cpf-description">
                {`Para sua segurança, informe os ${maxLength} 
                  primeiros dígitos do seu ${usuario.tipo.toUpperCase()} 
                  para autenticar o seu acesso e exibir sua simulação.`}
              </p>
              <div className="container-tela-cpf-buttons">
                <button
                  className="tela-cpf-acessar"
                  type="button"
                  disabled={disabled}
                  onClick={confirmarCPF}
                  ref={btnNegociarTopRef}
                >
                  Negociar agora
                </button>
                <button
                  type="button"
                  className="tela-cpf-nao-conheco"
                  onClick={() => setOpenModal(!openModal)}
                >
                  Não conheço essa pessoa
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-tela-cpf-information">
        <div className="container-tela-cpf-benefits">
          <h1>Confira as vantagens especiais para você</h1>
          <p>
            Por aqui você aproveita vantagens exclusivas para negociar sua
            dívida e decide tudo sem precisar ligar ou sair de casa.
          </p>

          <div className="container-tela-cpf-benefits-items">
            <div className="container-group-items">
              <div className="benefits-item">
                <div>
                  <img src={Porcentagem} alt="Calendário" />
                </div>
                <p>Oferta exclusiva para pagamento online</p>
                <span>
                  Pagando agora você garante descontos exclusivos para negociar
                  sua dívida sem sair de casa.
                </span>
              </div>

              <div className="benefits-item">
                <div>
                  <img src={Like} alt="Parcelas que cabem no seu bolso" />
                </div>
                <p>Parcelas que cabem no seu bolso</p>
                <span>
                  Aproveite o parcelamento com taxas baixas e economize seu
                  dinheiro.
                </span>
              </div>
            </div>
            <div className="container-group-items">
              <div className="benefits-item">
                <div>
                  <img
                    src={Calendar}
                    alt="Escolha o melhor dia de vencimento"
                  />
                </div>
                <p>Escolha o melhor dia de vencimento</p>
                <span>
                  Por aqui você consegue planejar o melhor dia para pagar sua
                  dívida.
                </span>
              </div>

              <div className="benefits-item">
                <div>
                  <img src={Phone} alt="Facilidade e segurança" />
                </div>
                <p>Facilidade e segurança</p>
                <span>
                  Negocie em apenas alguns minutos, com toda a segurança que a
                  página pode oferecer, seja pelo celular ou computador.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-tela-cpf-phases">
          <h1>Faça tudo em apenas 3 etapas informando apenas o seu CPF</h1>
          <p>Veja como é simples negociar sua dívida</p>
          <div className="container-tela-cpf-phases-items">
            <div className="phases-item">
              <img src={Etapa1} alt="etapa 1" />
              <div>
                <p>1º Informe os 3 primeiros dígitos do seu CPF</p>
                <span>
                  Primeiro informe somente os 3 primeiros dígitos do seu CPF
                  para reconhecermos você e te apresentar as melhores opções de
                  pagamento
                </span>
              </div>
            </div>

            <div className="phases-item">
              <img src={Etapa2} alt="etapa 2" />
              <div>
                <p>2º Escolha as melhores condições para negociar sua dívida</p>
                <span>
                  Escolha a quantidade de parcelas que deseja pagar e a melhor
                  data para a validade do seu boleto digital. Dessa forma você
                  consegue se organizar para liquidar a sua dívida.
                </span>
              </div>
            </div>

            <div className="phases-item">
              <img src={Etapa3} alt="etapa 3" />
              <div>
                <p>3º Gere o boleto e pague como preferir</p>
                <span>
                  Agora é só gerar o boleto com facilidade e segurança,
                  aproveitando as condições especiais para pagar como preferir,
                  através do app no celular, internet banking, ou em agências,
                  você decide!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-tela-cpf-whatsapp-session">
        <div className="container-whatsapp-session">
          <div className="container-whatsapp-session-img">
            <img src={MulherComCelular} alt="Mulher olhando para o celular" />
          </div>

          <div className="container-whatsapp-session-text">
            <h1>Você também pode conversar conosco através do WhatsApp</h1>
            <p>
              Se preferir falar sobre sua dívida pelo WhatsApp temos um
              especialista pronto para te ajudar, de maneira simples, rápida e
              segura. Nosso número é {maskPhone(numPhone)}.
            </p>
            <p>
              Verifique se o perfil do Itaú aparece com um check verde ao lado
              do nome e se no detalhe do perfil está escrito “conta comercial
              oficial”.
            </p>
            <p style={{ textAlign: "center" }}>
              <a
                ref={btnWhatsappRef}
                className="btn-negociar-whatsapp"
                href={`https://api.whatsapp.com/send?phone=${numPhone}`}
                target="_blank"
              >
                Negociar pelo whatsapp
              </a>
            </p>
          </div>
        </div>
      </div>

      <div ref={footerRef} className="container-tela-cpf-footer">
        <div className="content-footer">
          <div className="footer-logo-flex">
            <img src={LogoFlexFooter} alt="Flex" />
          </div>
          <p>
            Nós somos a FLEX, empresa parceira do Banco Itaú. Um canal oficial
            do seu banco para oferecer as melhores opções de renegociação para
            você quitar suas contas em aberto.
          </p>
        </div>
      </div>

      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ajustModal">
          <div className="center-center">
            <img src={Warning} style={{ width: "20%" }} alt="warning" />
          </div>
          <DialogTitle
            className="center-center"
            id="alert-dialog-title"
            style={{ paddingTop: 0 }}
          >
            <div className="fs-35">Atenção</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className="center-center"
              id="alert-dialog-description"
              style={{ whiteSpace: "nowrap", color: "#252220", fontSize: 20 }}
            >
              Confirma que não conhece o cliente?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="center-center modal-buttons-options">
            <button
              className="Button-cpf"
              onClick={() => setOpenModal(!openModal)}
            >
              <b>Voltar</b>
            </button>
            <button className="Button-cpf" onClick={naoConhece}>
              <b>Confirmar</b>
            </button>
          </DialogActions>
        </div>
      </Dialog>
      <div className="container-tela-cpf-float-bottons">
        <button
          ref={btnNegociarRef}
          onClick={confirmarCPF}
          type="button"
          className="btn-negociar"
        >
          Negociar agora mesmo
        </button>

      </div>
    </>
  );
}
