import React from 'react';
import './DetalhesDivida.css';

import CardParcela from '../card-parcela/card-parcela';

const DetalhesDivida = props => {
	return (
		<div className='container-detalhes-modal'>
			<div className='wrap-detalhes-modal'>
				<div>
				{props.detalhes.detalhe ? 
					props.detalhes.detalhe.map(d => {
						if ('parcelas' !== d.nome && 'mensagem' !== d.nome) {
							return (
								<div key={d.nome+'/'+d.valor}>
									<p>
										<b>{d.nome}</b>
										{`: ${d.valor}`}
									</p>
									<div className="wrap-detalhes-modal-cards">
										<CardParcela parcelas={d.parcelas}/>
									</div>
								</div>
							);
						}
						return null;
					})
					: null
				}
				</div>
				{props.detalhes.parcelas ? 
				<div className="wrap-detalhes-modal-cards">
					<CardParcela parcelas={props.detalhes.parcelas}/>
				</div>
				: null
				}
				<div style={{ margin: '10px auto 0px auto', display: 'flex' }}>
					{props.detalhes.mensagem ? 
						<div className='title-mensagem-detalhes-acordo'>
							{props.detalhes.mensagem}
						</div>
						: null
					}
				</div>
				<div style={{ margin: 'auto auto 0px auto', display: 'flex' }}>
					<button
						style={{ height: '30px', margin: '10px auto 15px auto', width: '100px', textAlign: 'center', background: '#FAB628' }}
						className='Button'
						onClick={props.onClose}>
						Ok
					</button>
				</div>
			</div>
		</div>
	);
};

export default DetalhesDivida;
