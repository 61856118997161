export default
    {
        "url_prod": "https://apiboletodigital.flexcontact.com.br/",
        "url_test": "https://apiboletodigital.xlab.work/",
        "url_dev": "http://localhost:9018/",
        // "url_dev": "http://10.10.120.49:9018/",

        "gtag_prod": "UA-129342007-6",
        "gtag_hml": "UA-129342007-5"
    }

    
