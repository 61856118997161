import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";

import ReactGA from "react-ga";
import Splash from "../splash/splash";
import Loading from "../loading/loading";
import TelaCPF from "../telaCPF/telaCPF";
import Error from "../telaErro/telaErro";
import ErrorModal from "../../components/errorModal/errorModal";
import TelaInicial from "../telaInicial/telaInicial";
import BoletoGerado from "../boletoGerado/boletoGerado";
import TelaPDF from "../telaPDF/telaPDF";
import TelaDesconhece from "../telaDesconhece/telaDesconhece";

import Whatsapp from "../../imgs/whatsapp.png";

import CPFService from "../../services/cpf";
import config from "../../config/config";

export default function Main() {
  ReactGA.initialize(
    process.env.REACT_APP_ENV === "prod" ? config.gtag_prod : config.gtag_hml
  );
  ReactGA.pageview(window.location.pathname);

  const estado = useSelector((state) => state.estadoReducer);
  const path = createBrowserHistory().location.pathname;
  const dispatch = useDispatch();
  const cpfService = new CPFService(estado);

  const [numPhone, setNumPhone] = useState("");

  useEffect(() => {
    const url = path.split("/").filter((n) => n);
    dispatch({ type: "SET_URL", payload: url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (estado.id && estado.product && !!numPhone) {
      setTimeout(() => window.location.reload(), 1000 * 60 * 5);
      carregaPagina();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado.id, numPhone]);

  const carregaPagina = () => {
    cpfService
      .getCPF()
      .then((data) => {
        if (data.success) {
          // salva cookie pra inserir no historico só 1 vez
          const historico = JSON.parse(localStorage.getItem("historico"));

          if (historico && historico.length > 0) {
            localStorage.setItem(
              "historico",
              JSON.stringify([...historico, estado.id_tel])
            );
          } else {
            localStorage.setItem("historico", JSON.stringify([estado.id_tel]));
          }
          dispatch({ type: "SET_USUARIO", payload: data.result });
          // dispatch({ type: "SET_CONFIRMA_CPF", payload: true });
          setTimeout(() => {
            dispatch({ type: "SET_SPLASH_PAGE", payload: false });
            dispatch({ type: "SET_CONFIRMA_CPF", payload: true });
          }, 2000);
          // 	mensagemacao: data.result.mensagemacao,
          // 	mensagemacaogerado: data.result.mensagemacaogerado,
        } else {
          dispatch({
            type: "SET_ERROR",
            payload: {
              errorMessage: data.mensagem,
              errorTitle: "Não conseguimos gerar seu boleto :(",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_ERROR",
          payload: {
            errorMessage: "Erro no Servidor",
            errorTitle: "Não conseguimos gerar seu boleto :(",
          },
        });
      });
  };

  useEffect(() => {
    if (estado.product)
      switch (estado.product) {
        case "pfv":
        case "pfs":
        case "pfe":
          setNumPhone("5511991983429");
          break;
        case "ctv":
        case "cts":
        case "cte":
          setNumPhone("551149493694");
          break;
        case "pfb":
          setNumPhone("5511991969923");
          break;
        case "ctb":
          setNumPhone("551149496309");
          break;
        default:
          setNumPhone("");
      }
  }, [estado]);

  return (
    <>
      {estado.splashPage ? <Splash /> : null}
      {estado.loadingPage ? <Loading /> : null}
      {estado.confirmaCpf ? <TelaCPF /> : null}
      {estado.telaInicial ? <TelaInicial /> : null}
      {estado.boletoGerado ? <BoletoGerado /> : null}
      {estado.visualizarPDF ? <TelaPDF /> : null}
      {estado.error ? <Error /> : null}
      {estado.errorModal ? <ErrorModal /> : null}
      {estado.telaDesconhece ? <TelaDesconhece /> : null}

      <div className="container-tela-cpf-float-bottons">
        {!!numPhone && (
          <a
            id="btn-whatsapp-main-float"
            href={`https://api.whatsapp.com/send?phone=${numPhone}`}
            target="_blank"
            className="btn-whatsapp"
          >
            <img src={Whatsapp} alt="whatsapp"></img>
          </a>
        )}
      </div>
    </>
  );
}
