import rp from 'request-promise';
import BaseService from './base.service';

export default class CPFService extends BaseService {
	constructor(state) {
		super(state);
		this.url = `${this.url}cpf/${this.version}`;
	}

	getCPF() {
		// cookie se ja inseriu no historico
		const historico = JSON.parse(localStorage.getItem('historico'));
		let enviaHistorico = true;

		if (historico && historico.length > 0 && historico.includes(this.id_tel)) {
			enviaHistorico = false;
		}

		return fetch(`${this.url}?id=${this.id}&telefone=${this.id_tel}&historico=${enviaHistorico}`).then(res =>
			res.json()
		);
	}

	async validateCPF(digits) {
		const options = {
			method: 'POST',
			url: `${this.url}`,
			qs: {
				id: this.id,
				telefone: this.id_tel
			},
			headers: {
				'Content-Type': 'application/json'
			},
			body: { cpf: digits },
			json: true
		};

		return rp(options);
	}
}