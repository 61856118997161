import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Flex from '../../imgs/flex-logo.png';
import ReactGA from 'react-ga';

import './telaPDF.css';

export default function TelaPDF() {
	const dispatch = useDispatch();
	const linkPDF = useSelector(state => state.boletoReducer.linkPDF);

	useEffect(() => {
		dispatch({ type: 'SET_LOADING_PAGE', payload: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkPDF]);

	const handleVisualzarClicked = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Visualizar Boleto PDF'
		});
		const win = window.open(linkPDF, '_blank');
		win.focus();
	};

	return (
		<div className='container-tela-pdf'>
			<div className='tela-pdf-logo'>
				<img src={Flex} alt='flex' />
			</div>
			<div className='tela-pdf-iframe'>
				<iframe title="boleto" src={`${linkPDF}#zoom=100`} frameBorder="0" type="application/pdf" />
			</div>
			<div className='tela-pdf-button'>
				<button onClick={handleVisualzarClicked}>Download Boleto</button>
			</div>
		</div>
	);
}
