import React from "react";

import logoItau from "../../imgs/itau.svg";
import logoFlex from "../../imgs/flex.svg";
import "./styles.css";

export default function HeaderBar() {
  return (
    <div className="container-header">
      <div className="header-content">
        <div className="header-content-ancora" />
        <div className="container-logo">
          <img className="logo-itau" src={logoItau} alt="logo iatú" />
          <div className="divider" />
          <div className="content-logo">
            <img className="logo-flex" src={logoFlex} alt="logo flex" />
            <p>Parceiro autorizado</p>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}
