import React, { useEffect } from "react";
import Flex from "../../imgs/flex.png";
import logoItau from "../../imgs/itau.svg";
import logoFlex from "../../imgs/flex.svg";
import warning from "../../imgs/warning.png";

import { useSelector, useDispatch } from "react-redux";

import "./telaErro.css";

export default function TelaErro() {
  const telefone = useSelector((state) => state.usuarioReducer.telefone);
  const errorMessage = useSelector((state) => state.estadoReducer.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_LOADING_PAGE", payload: false });
    dispatch({ type: "SET_SPLASH_PAGE", payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telefone, errorMessage]);

  return (
    <div className="container-erro">
      <div className="container-erro-content">
        <div className="container-logo">
          <img className="logo-itau" src={logoItau} alt="logo iatú" />
          <div className="divider" />
          <div className="content-logo">
            <img className="logo-flex" src={logoFlex} alt="logo flex" />
            <p>Parceiro autorizado</p>
          </div>
        </div>
        <img style={{ maxWidth: 150 }} src={warning} alt="Alerta" />
        <div className="container-erro-items">
          <div className="container-erro-label">{errorMessage}</div>
          <p style={{ color: "#252220" }}>Entre em Contato com a Flex</p>
          <p style={{ color: "#252220" }}>{telefone}</p>
        </div>
      </div>
    </div>
  );
}
