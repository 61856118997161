import React from "react";

import HeaderBar from "../../components/HeaderBar";

import "./styles.css";

export default function Splash() {
  
  return (
    <div className="container">
      <HeaderBar />

      <div className="container-body">
        <div className="body-content">
          
          <h1>É desconto e parcelamento que você está procurando?</h1>
          <p>Negocie agora mesmo! Temos uma oferta única e especial para você! Aproveite!</p>
          </div>
      </div>
    </div>
  );
}
